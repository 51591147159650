.uploads {
  position:fixed;
  bottom:15px;
  right:15px;
  background-color: #0002;
  border-radius:4px;
  box-shadow: 1px 1px 5px #0003;
  padding:10px;
  & .uploads-header {
    margin-bottom:10px;
  }
  & .uploads-list {
    text-align:center;
  }
  & .uploads-list-item {
    display: inline-block;
  }
  & .MuiBox-root {
    padding:0 5px;
    user-select: none;
  }
}
