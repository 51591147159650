
$mainColor:#b4a365;
$mainColorLight1:#b4a36533;
$mainColorLight2:#b4a36566;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-xxl-min: 1600px;

// XSmall devices
@mixin xs {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-xl-min}) {
       @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xxl-min}) {
       @content;
    }
}
