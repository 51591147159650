.input-text {
  margin: 10px 0;
  &.invalid .MuiOutlinedInput-notchedOutline, &.invalid .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.invalid .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  .input-text-message {
    text-align:right;
    font-size:0.8rem;
    line-height:1.5;
  }
}
