@import "./variables.scss";
.mini-spacer {
  height:10px;
}
.MuiTypography-root.MuiTypography-h1 {
  @include xs {
    font-size: 3.5rem;
  }
  max-width:100%;
  overflow:hidden;
}
.MuiTypography-root.MuiTypography-h2 {
  @include xs {
    font-size: 1.2rem;
  }
  max-width:100%;
  overflow:hidden;
}
.bottom-padding {
  height:15px;
}
.floating-action {
  position:fixed;
  width:100%;
  bottom:50px;
  left:0;
  pointer-events: none;
  &>div {
    display:flex;
    justify-content: center;
    button {
      pointer-events: all;
    }
  }
}
.highlight {
  color:$mainColor;
  font-size:1.5em;
}
a:-webkit-any-link {
  text-decoration: none;
}
